import React from "react"
import Layout from "../components/layout"
import { FiPhone } from "react-icons/fi"

import arenda from "../images/construction.jpg"

const Arenda = () => {
  return (
    <Layout>
      <div className="mt-8">
        <div className="bg-transparent">
          <h2
            className="font-bold text-2xl md:text-3xl lg:text-4xl xl:text-4xl"
            style={{
              fontFamily: `Montserrat`,
              color: `white`,
              padding: `1rem`,
            }}
          >
            АРЕНДА СТРОИТЕЛЬНОГО ОБОРУДОВАНИЯ
          </h2>
          <p className="text-white ml-2 md:ml-2 text-sm md:text-sm lg:text-base xl:text-base">
            Позвоните и уточните стоимость и наличие оборудования
          </p>
          <span
            className="flex flex-row ml-2 md:ml-2 text-base md:text-base lg:text-base xl:text-base"
            style={{ fontFamily: `Montserrat` }}
          >
            <FiPhone size={25} className="text-white" />
            <a href="tel:+79379377000" className="text-white pl-2 pb-4">
              +7 937 937 70 00
            </a>
          </span>
        </div>
      </div>

      <div className="mt-12 flex flex-col md:flex-row">
        <div>
          <img src={arenda} alt={"Аренда оборудования"} />
        </div>
        <div>
          <p
            className="md:ml-6 text-sm md:text-sm lg:text-sm xl:text-sm"
            style={{
              fontFamily: `Montserrat`,
              color: `#F1F1F2`,
            }}
          >
            Компания ФС-Технологии, оказывает услуги по аренде строительного
            оборудования.
          </p>
        </div>
      </div>

      <section>
        <table
          className="table-auto mt-12"
          style={{ fontFamily: `Montserrat`, color: `white` }}
        >
          <thead className="mb-6">
            <tr style={{ borderBottom: `1px solid white` }}>
              <th className="px-1 py-2 text-base md:text-2xl lg:text-2xl xl:text-2xl">
                Наименование
              </th>
              <th className="px-2 py-2 text-base md:text-2xl lg:text-2xl xl:text-2xl">
                Стоимость{" "}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="w-full" style={{ borderTop: `1px solid white` }}>
              <td className="border px-4 py-2 md:px-4 md:py-2 lg:py-2 lg:px-4 text-sm md:text-sm lg:text-sm xl:text-sm">
                Бензобур ( бур 150, 200, 250, 300 мм . глубина до 1,8 м)
              </td>
              <td className="border px-4 py-2 md:px-4 md:py-2 lg:py-2 lg:px-4 text-sm md:text-sm lg:text-sm xl:text-sm">
                От 930 &#x20bd;
              </td>
            </tr>
            <tr>
              <td className="border px-4 py-2 text-sm md:text-sm lg:text-sm xl:text-sm">
                Бензогенератор 3квт
              </td>
              <td className="border px-4 py-2 text-sm md:text-sm lg:text-sm xl:text-sm">
                820 &#x20bd;
              </td>
            </tr>
            <tr>
              <td className="border px-4 py-2 text-sm md:text-sm lg:text-sm xl:text-sm">
                Бензогенератор 5 квт
              </td>
              <td className="border px-4 py-2 text-sm md:text-sm lg:text-sm xl:text-sm">
                1020 &#x20bd;
              </td>
            </tr>
            <tr>
              <td className="border px-4 py-2 text-sm md:text-sm lg:text-sm xl:text-sm">
                Бензопила stihl
              </td>
              <td className="border px-4 py-2 text-sm md:text-sm lg:text-sm xl:text-sm">
                750 &#x20bd;
              </td>
            </tr>
            <tr>
              <td className="border px-4 py-2 text-sm md:text-sm lg:text-sm xl:text-sm">
                Бетонолом bosch 25 дж
              </td>
              <td className="border px-4 py-2 text-sm md:text-sm lg:text-sm xl:text-sm">
                950 &#x20bd;
              </td>
            </tr>
            <tr>
              <td className="border px-4 py-2 text-sm md:text-sm lg:text-sm xl:text-sm">
                Бетономешалка 180л
              </td>
              <td className="border px-4 py-2 text-sm md:text-sm lg:text-sm xl:text-sm">
                420 &#x20bd;
              </td>
            </tr>
            <tr>
              <td className="border px-4 py-2 text-sm md:text-sm lg:text-sm xl:text-sm">
                Болгарка ушм д230
              </td>
              <td className="border px-4 py-2 text-sm md:text-sm lg:text-sm xl:text-sm">
                420 &#x20bd;
              </td>
            </tr>
            <tr>
              <td className="border px-4 py-2 text-sm md:text-sm lg:text-sm xl:text-sm">
                Вибратор глубинный для бетона (шланг 4м )
              </td>
              <td className="border px-4 py-2 text-sm md:text-sm lg:text-sm xl:text-sm">
                920 &#x20bd;
              </td>
            </tr>
            <tr>
              <td className="border px-4 py-2 text-sm md:text-sm lg:text-sm xl:text-sm">
                Виброплита 160 кг (высокая производительность)
              </td>
              <td className="border px-4 py-2 text-sm md:text-sm lg:text-sm xl:text-sm">
                1500 &#x20bd;
              </td>
            </tr>
            <tr>
              <td className="border px-4 py-2 text-sm md:text-sm lg:text-sm xl:text-sm">
                Вышки тура (высота до 7,5 м.)
              </td>
              <td className="border px-4 py-2 text-sm md:text-sm lg:text-sm xl:text-sm">
                400  &#x20bd;
              </td>
            </tr>
            <tr>
              <td className="border px-4 py-2 text-sm md:text-sm lg:text-sm xl:text-sm">
                Виброрейка 1,7м
              </td>
              <td className="border px-4 py-2 text-sm md:text-sm lg:text-sm xl:text-sm">
                900 &#x20bd;
              </td>
            </tr>
            <tr>
              <td className="border px-4 py-2 text-sm md:text-sm lg:text-sm xl:text-sm">
                Газовый баллон металлический
              </td>
              <td className="border px-4 py-2 text-sm md:text-sm lg:text-sm xl:text-sm">
                200 &#x20bd;
              </td>
            </tr>
            <tr>
              <td className="border px-4 py-2 text-sm md:text-sm lg:text-sm xl:text-sm">
                Горелка газовая
              </td>
              <td className="border px-4 py-2 text-sm md:text-sm lg:text-sm xl:text-sm">
                300 &#x20bd;
              </td>
            </tr>
            <tr>
              <td className="border px-4 py-2 text-sm md:text-sm lg:text-sm xl:text-sm">
                Дальномер
              </td>
              <td className="border px-4 py-2 text-sm md:text-sm lg:text-sm xl:text-sm">
                350 &#x20bd;
              </td>
            </tr>
            <tr>
              <td className="border px-4 py-2 text-sm md:text-sm lg:text-sm xl:text-sm">
                Детектор проводки цифровой
              </td>
              <td className="border px-4 py-2 text-sm md:text-sm lg:text-sm xl:text-sm">
                500 &#x20bd;
              </td>
            </tr>
            <tr>
              <td className="border px-4 py-2 text-sm md:text-sm lg:text-sm xl:text-sm">
                Дисковая пила Makita 2квт (циркулярка ручная)
              </td>
              <td className="border px-4 py-2 text-sm md:text-sm lg:text-sm xl:text-sm">
                500 &#x20bd;
              </td>
            </tr>
            <tr>
              <td className="border px-4 py-2 text-sm md:text-sm lg:text-sm xl:text-sm">
                Домкрат железнодорожный реечный
              </td>
              <td className="border px-4 py-2 text-sm md:text-sm lg:text-sm xl:text-sm">
                1000 &#x20bd;
              </td>
            </tr>
            <tr>
              <td className="border px-4 py-2 text-sm md:text-sm lg:text-sm xl:text-sm">
                Домкрат подкатной
              </td>
              <td className="border px-4 py-2 text-sm md:text-sm lg:text-sm xl:text-sm">
                420 &#x20bd;
              </td>
            </tr>
            <tr>
              <td className="border px-4 py-2 text-sm md:text-sm lg:text-sm xl:text-sm">
                Дрель малая
              </td>
              <td className="border px-4 py-2 text-sm md:text-sm lg:text-sm xl:text-sm">
                420 &#x20bd;
              </td>
            </tr>
            <tr>
              <td className="border px-4 py-2 text-sm md:text-sm lg:text-sm xl:text-sm">
                Дрель низкооборотистая
              </td>
              <td className="border px-4 py-2 text-sm md:text-sm lg:text-sm xl:text-sm">
                500 &#x20bd;
              </td>
            </tr>
            <tr>
              <td className="border px-4 py-2 text-sm md:text-sm lg:text-sm xl:text-sm">
                Зарядное устройство для аккумулятора
              </td>
              <td className="border px-4 py-2 text-sm md:text-sm lg:text-sm xl:text-sm">
                400 &#x20bd;
              </td>
            </tr>
            <tr>
              <td className="border px-4 py-2 text-sm md:text-sm lg:text-sm xl:text-sm">
                Клещи обжимные valtec
              </td>
              <td className="border px-4 py-2 text-sm md:text-sm lg:text-sm xl:text-sm">
                520 &#x20bd;
              </td>
            </tr>
            <tr>
              <td className="border px-4 py-2 text-sm md:text-sm lg:text-sm xl:text-sm">
                Компрессор производительность 200 л/мин , 420 л/мин
              </td>
              <td className="border px-4 py-2 text-sm md:text-sm lg:text-sm xl:text-sm">
                От 730 &#x20bd;
              </td>
            </tr>
            <tr>
              <td className="border px-4 py-2 text-sm md:text-sm lg:text-sm xl:text-sm">
                Краскораспылитель
              </td>
              <td className="border px-4 py-2 text-sm md:text-sm lg:text-sm xl:text-sm">
                200 &#x20bd;
              </td>
            </tr>
            <tr>
              <td className="border px-4 py-2 text-sm md:text-sm lg:text-sm xl:text-sm">
                Лебедка цепная (таль ручная) 2т
              </td>
              <td className="border px-4 py-2 text-sm md:text-sm lg:text-sm xl:text-sm">
                500 &#x20bd;
              </td>
            </tr>
            <tr>
              <td className="border px-4 py-2 text-sm md:text-sm lg:text-sm xl:text-sm">
                Лазерный уровень + штатив
              </td>
              <td className="border px-4 py-2 text-sm md:text-sm lg:text-sm xl:text-sm">
                420 &#x20bd;
              </td>
            </tr>
            <tr>
              <td className="border px-4 py-2 text-sm md:text-sm lg:text-sm xl:text-sm">
                Лестница выдвижная стремянка ( рабочая высота 8 м)
              </td>
              <td className="border px-4 py-2 text-sm md:text-sm lg:text-sm xl:text-sm">
                500 &#x20bd;
              </td>
            </tr>
            <tr>
              <td className="border px-4 py-2 text-sm md:text-sm lg:text-sm xl:text-sm">
                Мозаично-шлифовальная машина по бетону
              </td>
              <td className="border px-4 py-2 text-sm md:text-sm lg:text-sm xl:text-sm">
                2000 &#x20bd;
              </td>
            </tr>
            <tr>
              <td className="border px-4 py-2 text-sm md:text-sm lg:text-sm xl:text-sm">
                Мотопомпа грязевая 1000л./мин. Шланги 40м
              </td>
              <td className="border px-4 py-2 text-sm md:text-sm lg:text-sm xl:text-sm">
                1200 &#x20bd;
              </td>
            </tr>
            <tr>
              <td className="border px-4 py-2 text-sm md:text-sm lg:text-sm xl:text-sm">
                Насос дренажный с шлангами 20 м
              </td>
              <td className="border px-4 py-2 text-sm md:text-sm lg:text-sm xl:text-sm">
                350 &#x20bd;
              </td>
            </tr>
            <tr>
              <td className="border px-4 py-2 text-sm md:text-sm lg:text-sm xl:text-sm">
                Нивелир оптический bosch + линейка 5м + тренога
              </td>
              <td className="border px-4 py-2 text-sm md:text-sm lg:text-sm xl:text-sm">
                850 &#x20bd;
              </td>
            </tr>
            <tr>
              <td className="border px-4 py-2 text-sm md:text-sm lg:text-sm xl:text-sm">
                Паркетошлифовальная машина
              </td>
              <td className="border px-4 py-2 text-sm md:text-sm lg:text-sm xl:text-sm">
                1500 &#x20bd;
              </td>
            </tr>
            <tr>
              <td className="border px-4 py-2 text-sm md:text-sm lg:text-sm xl:text-sm">
                Плиткорез электрический
              </td>
              <td className="border px-4 py-2 text-sm md:text-sm lg:text-sm xl:text-sm">
                500 &#x20bd;
              </td>
            </tr>
            <tr>
              <td className="border px-4 py-2 text-sm md:text-sm lg:text-sm xl:text-sm">
                Пистолет гвоздезабивной
              </td>
              <td className="border px-4 py-2 text-sm md:text-sm lg:text-sm xl:text-sm">
                600 &#x20bd;
              </td>
            </tr>
            <tr>
              <td className="border px-4 py-2 text-sm md:text-sm lg:text-sm xl:text-sm">
                Прожектор , переноска
              </td>
              <td className="border px-4 py-2 text-sm md:text-sm lg:text-sm xl:text-sm">
                300 &#x20bd;
              </td>
            </tr>
            <tr>
              <td className="border px-4 py-2 text-sm md:text-sm lg:text-sm xl:text-sm">
                Пылесос строительный
              </td>
              <td className="border px-4 py-2 text-sm md:text-sm lg:text-sm xl:text-sm">
                600 &#x20bd;
              </td>
            </tr>
            <tr>
              <td className="border px-4 py-2 text-sm md:text-sm lg:text-sm xl:text-sm">
                Сварочный аппарат для полипропилена до 110мм
              </td>
              <td className="border px-4 py-2 text-sm md:text-sm lg:text-sm xl:text-sm">
                От 420 &#x20bd;
              </td>
            </tr>
            <tr>
              <td className="border px-4 py-2 text-sm md:text-sm lg:text-sm xl:text-sm">
                Сварочный инвертор с маской
              </td>
              <td className="border px-4 py-2 text-sm md:text-sm lg:text-sm xl:text-sm">
                От 700 &#x20bd;
              </td>
            </tr>
            <tr>
              <td className="border px-4 py-2 text-sm md:text-sm lg:text-sm xl:text-sm">
                Строительные леса ( порядка 1500 м2)
              </td>
              <td className="border px-4 py-2 text-sm md:text-sm lg:text-sm xl:text-sm">
                От 60 &#x20bd; в месяц /1м2
              </td>
            </tr>
            <tr>
              <td className="border px-4 py-2 text-sm md:text-sm lg:text-sm xl:text-sm">
                Стойки телескопические (домкраты)
              </td>
              <td className="border px-4 py-2 text-sm md:text-sm lg:text-sm xl:text-sm">
                100р/сут
              </td>
            </tr>
            <tr>
              <td className="border px-4 py-2 text-sm md:text-sm lg:text-sm xl:text-sm">
                Тепловая пушка (газовая )
              </td>
              <td className="border px-4 py-2 text-sm md:text-sm lg:text-sm xl:text-sm">
                620 &#x20bd;
              </td>
            </tr>
            <tr>
              <td className="border px-4 py-2 text-sm md:text-sm lg:text-sm xl:text-sm">
                Тепловая пушка электрическая 2квт
              </td>
              <td className="border px-4 py-2 text-sm md:text-sm lg:text-sm xl:text-sm">
                520 &#x20bd;
              </td>
            </tr>
            <tr>
              <td className="border px-4 py-2 text-sm md:text-sm lg:text-sm xl:text-sm">
                Торцовочная пила Metabo
              </td>
              <td className="border px-4 py-2 text-sm md:text-sm lg:text-sm xl:text-sm">
                530 &#x20bd;
              </td>
            </tr>
            <tr>
              <td className="border px-4 py-2 text-sm md:text-sm lg:text-sm xl:text-sm">
                Удлинители
              </td>
              <td className="border px-4 py-2 text-sm md:text-sm lg:text-sm xl:text-sm">
                300 &#x20bd;
              </td>
            </tr>
            <tr>
              <td className="border px-4 py-2 text-sm md:text-sm lg:text-sm xl:text-sm">
                Фен строительный
              </td>
              <td className="border px-4 py-2 text-sm md:text-sm lg:text-sm xl:text-sm">
                400 &#x20bd;
              </td>
            </tr>
            <tr>
              <td className="border px-4 py-2 text-sm md:text-sm lg:text-sm xl:text-sm">
                Фрезер по дереву
              </td>
              <td className="border px-4 py-2 text-sm md:text-sm lg:text-sm xl:text-sm">
                500 &#x20bd;
              </td>
            </tr>
            <tr>
              <td className="border px-4 py-2 text-sm md:text-sm lg:text-sm xl:text-sm">
                Цинлиндр гидравлический (сжим- разжим , гидравлика , клин)
              </td>
              <td className="border px-4 py-2 text-sm md:text-sm lg:text-sm xl:text-sm">
                600 &#x20bd;
              </td>
            </tr>
            <tr>
              <td className="border px-4 py-2 text-sm md:text-sm lg:text-sm xl:text-sm">
                Шлифовальная машина экцентриковая по дереву
              </td>
              <td className="border px-4 py-2 text-sm md:text-sm lg:text-sm xl:text-sm">
                520 &#x20bd;
              </td>
            </tr>
            <tr>
              <td className="border px-4 py-2 text-sm md:text-sm lg:text-sm xl:text-sm">
                Штроборез
              </td>
              <td className="border px-4 py-2 text-sm md:text-sm lg:text-sm xl:text-sm">
                720 &#x20bd;
              </td>
            </tr>
            <tr>
              <td className="border px-4 py-2 text-sm md:text-sm lg:text-sm xl:text-sm">
                Электропила Makita
              </td>
              <td className="border px-4 py-2 text-sm md:text-sm lg:text-sm xl:text-sm">
                600 &#x20bd;
              </td>
            </tr>
          </tbody>
        </table>
      </section>
    </Layout>
  )
}

export default Arenda
